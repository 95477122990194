<template>
  <div id="webinar">
    <div class="demo-main-photo" :style="{background: `url('${webinarImage}') 50% 50%`, backgroundSize: 'cover',}">
      <div class="demo-main-photo-title">
        <div v-html="getTranslateFromItem(main.translation, 'title')"></div>
        <div class="demo-main-photo-title-date">
          {{ getTranslateFromItem(main.translation, 'date') }}
        </div>
      </div>
      <div class="page-section-reg-wrapper">
        <a @click.prevent="scrollToRegSection">
          <BaseButton bordered>Registration</BaseButton>
        </a>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          {{ getTranslateFromItem(description.translation, 'title') }}
        </div>
        <div
          :style="{
            fontSize: '20px',
            lineHeight: '30px',
            padding: '0px',
            whiteSpace: 'pre-wrap',
          }"
        >
          <div v-html="descriptionBefore"></div>
          <ul>
            <li v-for="e in description.list">{{ getTranslateFromItem(e.translate, 'text') }}</li>
          </ul>
          <div>{{ getTranslateFromItem(description.translation, 'after') }}</div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslateFromItem(program.translation, 'title') }}</div>
        <div v-for="e in program.days" class="programme-item">
          <div class="programme-item-day">
            <div>{{ getTranslateFromItem(e.translation, 'title') }}</div>
            <div>{{ getTranslateFromItem(e.translation, 'date') }}</div>
            <div>{{ getTranslateFromItem(e.translation, 'time') }}</div>
          </div>
          <div class="programme-item-content">
            <ol
                type="1"
            >
              <li
                v-for="(item, i) in e.programme"
                class="programme-item-content-item"
              >
                <div class="programme-item-content-item-title">{{ getTranslateFromItem(item.translation, 'title') }}</div>
                <div class="programme-item-content-item-speaker" v-if="item.general.speaker">{{ getTranslateFromItem(item.translation, 'speaker') }}</div>
                <div class="programme-item-content-item-modules">{{ getTranslateFromItem(item.translation, 'modules') }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div
        v-for="e in targets"
        class="page-section-wrapper marketing-section-analytics-wrapper"
        :class="{
          'marketing-section-analytics-wrapper__reverse': e.general.reverse,
        }"
    >
      <div
          class="page-section-inner marketing-section-analytics"
      >
        <div class="marketing-section-analytics-image">
          <img :src="e.general.photo" alt="">
        </div>
        <div class="marketing-section-analytics-info">
          <div class="marketing-section-analytics-info-title">
            {{ getTranslateFromItem(e.translation, 'title') }}
          </div>
          <div class="marketing-section-analytics-info-description" v-html="getTranslateFromItem(e.translation, 'description')"></div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">{{ getTranslate('webinarSpeakersTitle') }}</div>
        <CarouselWebinar :speakers="speakers"></CarouselWebinar>
      </div>
    </div>
    <div class="page-section-wrapper" id="home-section-integrations-wrapper">
      <div class="page-section-inner" id="home-section-integrations">
        <div id="home-section-integrations-title" class="page-section-title-center">
          {{ getTranslate('webinarPartnersTitle') }}
        </div>
        <div id="home-section-integrations-logos">
          <a :href="e.generic.url" target="_blank" class="home-section-integrations-logos-item" v-for="e in partners">
            <img :src="e.generic.photo" alt="">
          </a>
        </div>
      </div>
    </div>
    <div id="regSection" class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">
          <small>
            Reserve your spot now for this exclusive webinar and transform your approach to revenue management.
          </small>
        </div>
        <div class="page-section-title-center">{{ getTranslate('webinarFormTitle') }}</div>
        <div id="hubspotForm" ref="hubspot-form1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import webinarImage from '@/assets/images/webinar/webinar3.png';
import speakerKatsimprasImage from '@/assets/images/speakers/theodoros-katsimpras.jpg';
import speakerPouliosImage from '@/assets/images/speakers/tp_enhanced.png';
import speakerNikImage from '@/assets/images/speakers/nikos.png';
import bookOnlineImage from '@/assets/images/partners/book-online-now.png';
import hotelAvailImage from '@/assets/images/partners/hotel-availabilities.svg';
import {mapGetters} from "vuex";
import CarouselWebinar from "@/components/CarouselWebinar";
import BaseButton from "@/components/BaseButton";

export default {
  name: "Webinar",
  computed: {
    ...mapGetters([
        'getTranslate',
        'getTranslateFromItem',
        'lang',
        'integrations',
    ]),
    descriptionBefore() {
      return this.getTranslateFromItem(this.description.translation, 'before');
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
  },
  methods: {
    scrollToRegSection() {
      const section = document.getElementById("regSection");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    appendForm() {
      this.$refs["hubspot-form1"].innerHTML = '';
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId: "1d8709e5-fa0f-413d-8136-aa75ea737346",
            target: '#hubspotForm',
          });
        }
      })
    },
  },
  components: {
    CarouselWebinar,
    BaseButton
  },
  data: () => ({
    webinarImage,
    main: {
      translation: {
        ru: {
          title: '<div>How Smart Distribution Drives Revenue Growth in Greek Hotels</div>',
          date: 'For every hotel in Greece, from boutique gems to seaside resorts \n Online | 26 February 2025 | 12:00 EST'
        },
        en: {
          title: '<div>How Smart Distribution Drives Revenue Growth in Greek Hotels</div>',
          date: 'For every hotel in Greece, from boutique gems to seaside resorts \n Online | 26 February 2025 | 12:00 EST'
        },
      },
    },
    description: {
      list: [
      ],
      translation: {
        ru: {
          title: 'What is this webinar about?',
          before: '<p>As the hospitality industry prepares for the upcoming season, hoteliers in Greece face increasing challenges in maintaining competitive pricing across different channels. With new OTA discounting models, shifting rate parity dynamics, and evolving direct booking strategies, distribution has never been more critical to revenue success.</p>' +
          '<p>Join us for a practical and insightful discussion on how hotels can leverage smart distribution tactics to optimize pricing, boost direct bookings, and balance OTA performance for maximum profitability.</p>' +
          '<p>Duration: 2 hours</p>' +
          '<p>Webinar language: Greek</p>',
          after: ''
        },
        en: {
          title: 'What is this webinar about?',
          before: '<p>As the hospitality industry prepares for the upcoming season, hoteliers in Greece face increasing challenges in maintaining competitive pricing across different channels. With new OTA discounting models, shifting rate parity dynamics, and evolving direct booking strategies, distribution has never been more critical to revenue success.</p>' +
          '<p>Join us for a practical and insightful discussion on how hotels can leverage smart distribution tactics to optimize pricing, boost direct bookings, and balance OTA performance for maximum profitability.</p>' +
          '<p>Duration: 2 hours</p>' +
          '<p>Webinar language: Greek</p>',
          after: ''
        },
      },
    },
    program: {
      days: [
        {
          general: {
            id: 0,
          },
          programme: [
            {
              general: {
                id: 0,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'How to Define the Right Pricing Strategy',
                  speaker: 'Nikolaos Tsitsoulis, Co-founder & Commercial Director, hotellab',
                  modules: '- Explore ways to demand analysis; \n' +
                  '- Understand why flexible pricing strategies are crucial for revenue optimization; \n' +
                  '- Recognize the importance of knowing your lead period.'
                },
                en: {
                  title: 'How to Define the Right Pricing Strategy',
                  speaker: 'Nikolaos Tsitsoulis, Co-founder & Commercial Director, hotellab',
                  modules: '- Explore ways to demand analysis; \n' +
                  '- Understand why flexible pricing strategies are crucial for revenue optimization; \n' +
                  '- Recognize the importance of knowing your lead period.'
                },
              },
            },
            {
              general: {
                id: 1,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Maximizing Direct Website Revenue Without Hurting OTA Performance',
                  speaker: 'Theodoros Katsimpras, COO, Book Online Now',
                  modules: '- Optimizing direct bookings while maintaining OTA visibility; \n' +
                  '- Reducing commission costs and strengthening brand presence; \n' +
                  '- Upselling through booking engines to boost revenue; \n' +
                  '- Balancing pricing to avoid OTA penalties and maintain profitability.'
                },
                en: {
                  title: 'Maximizing Direct Website Revenue Without Hurting OTA Performance',
                  speaker: 'Theodoros Katsimpras, COO, Book Online Now',
                  modules: '- Optimizing direct bookings while maintaining OTA visibility; \n' +
                  '- Reducing commission costs and strengthening brand presence; \n' +
                  '- Upselling through booking engines to boost revenue; \n' +
                  '- Balancing pricing to avoid OTA penalties and maintain profitability.'
                },
              },
            },
            {
              general: {
                id: 2,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'How to manage OTA/Website with different rate plans',
                  speaker: 'Thomas Poulios, Managing Director, HotelAvailabilities Channel Manager',
                  modules: '- Why online market is important; \n' +
                  '- The importance of each OTA for the hotel; \n' +
                  '- Choosing the perfect channel mix; \n' +
                  '- Managing different rate plans for each OTA or Website; \n' +
                  '- Different rates and availabilities to each OTA.'
                },
                en: {
                  title: 'How to manage OTA/Website with different rate plans',
                  speaker: 'Thomas Poulios, Managing Director, HotelAvailabilities Channel Manager',
                  modules: '- Why online market is important; \n' +
                  '- The importance of each OTA for the hotel; \n' +
                  '- Choosing the perfect channel mix; \n' +
                  '- Managing different rate plans for each OTA or Website; \n' +
                  '- Different rates and availabilities to each OTA.'
                },
              },
            },
            {
              general: {
                id: 3,
                speaker: true,
              },
              translation: {
                ru: {
                  title: 'Live Q&A session',
                  speaker: '',
                  modules: ''
                },
                en: {
                  title: 'Live Q&A session',
                  speaker: '',
                  modules: ''
                },
              },
            },
          ],
          translation: {
            ru: {
              title: '',
              date: '',
              time: ''
            },
            en: {
              title: '',
              date: '',
              time: ''
            },
          },
        },
      ],
      translation: {
        ru: {
          title: 'Programme:',
          description: '',
        },
        en: {
          title: 'Programme:',
          description: '',
        },
      },
    },
    partners: [
      {
        "id": 0,
        "generic": {
          "photo": bookOnlineImage,
          "url": "https://www.bookonlinenow.net/",
        },
      },
      {
        "id": 1,
        "generic": {
          "photo": hotelAvailImage,
          "url": "https://hotelavailabilities.com/",
        },
      },
    ],
    targets: [
      {
        general: {
          id: 0,
          photo: 'https://hotellab.io/media/webinarForWho2.png',
        },
        translation: {
          ru: {
            title: 'For who:',
            description: '<ul><li>Hotel owners & general managers<//li>' +
              '<li>Revenue managers & commercial teams</li>' +
              '<li>Hospitality professionals</li></ul>',
          },
          en: {
            title: 'For who:',
            description: '<ul><li>Hotel owners & general managers<//li>' +
              '<li>Revenue managers & commercial teams</li>' +
              '<li>Hospitality professionals</li></ul>',
          },
        },
      },
      {
        general: {
          reverse: true,
          id: 1,
          photo: 'https://hotellab.io/media/webinarForWhy2.png',
        },
        translation: {
          ru: {
            title: 'What you will learn:',
            description: '<ul><li>How to define a winning pricing strategy by analyzing demand, leveraging flexible pricing, and understanding lead periods</li>' +
            '<li>Optimizing rate plans and channel markups to maintain rate parity and maximize direct bookings</li>' +
            '<li>Balancing OTA performance and direct sales to strengthen brand presence and reduce commission costs</li>' +
            '<li>The role of an RMS and Channel Manager in creating dynamic, automated pricing strategies</li>' +
            '<li>Unlocking additional revenue streams through booking engine upselling, personalized offers, and strategic pricing adjustments</li></ul>'
          },
          en: {
            title: 'What you will learn:',
            description: '<ul><li>How to define a winning pricing strategy by analyzing demand, leveraging flexible pricing, and understanding lead periods</li>' +
            '<li>Optimizing rate plans and channel markups to maintain rate parity and maximize direct bookings</li>' +
            '<li>Balancing OTA performance and direct sales to strengthen brand presence and reduce commission costs</li>' +
            '<li>The role of an RMS and Channel Manager in creating dynamic, automated pricing strategies</li>' +
            '<li>Unlocking additional revenue streams through booking engine upselling, personalized offers, and strategic pricing adjustments</li></ul>'
          },
        },
      },
    ],
    speakers: [
      {
        general: {
          photo: speakerNikImage,
        },
        translation: {
          ru: {
            name: 'Nikolaos Tsitsoulis',
            description: 'Co-founder & Commercial Director, hotellab',
          },
          en: {
            name: 'Nikolaos Tsitsoulis',
            description: 'Co-founder & Commercial Director, hotellab',
          },
        },
      },
      {
        general: {
          photo: speakerKatsimprasImage,
        },
        translation: {
          ru: {
            name: 'Theodoros Katsimpras',
            description: 'COO, Book Online Now',
          },
          en: {
            name: 'Theodoros Katsimpras',
            description: 'COO, Book Online Now',
          },
        },
      },
      {
        general: {
          photo: speakerPouliosImage,
        },
        translation: {
          ru: {
            name: 'Thomas Poulios',
            description: 'Managing Director, HotelAvailabilities Channel Manager',
          },
          en: {
            name: 'Thomas Poulios',
            description: 'Managing Director, HotelAvailabilities Channel Manager',
          },
        },
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
@media (max-width: $breakpoint-mobile) {
  .marketing-section-analytics-info-description {
    text-align: left;
  }
}

.page-section-wrapper.pt-0 {
  padding-top: 0;
}

.page-section-reg-wrapper {
  padding: 30px;
}

.page-section-reg-wrapper span {
  margin-right: 20px;
}

.demo-main-photo-title-date {
  white-space: pre-wrap;
}

.programme-item {
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  background-color: #F9FAFF;

  .page-section-reg-wrapper {
    padding: 30px;
  }

  .page-section-reg-wrapper span {
    margin-right: 20px;
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }


  &-day {
    font-weight: 900;
    font-size: 30px;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    background-color: #5d5ca8;
    color: white;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      text-align: center;
      padding: 25px;
      font-size: 24px;
    }
  }

  &-content {
    white-space: pre-wrap;
    font-size: 21px;
    line-height: 30px;
    padding: 40px;
    flex-grow: 1;

    @media (max-width: $breakpoint-tablet) {
      padding: 25px;

      @media (max-width: $breakpoint-mobile) {
        padding: 10px;
      }
    }

    ol {
      margin: 0;
    }

    &-item {
      margin-bottom: 20px;

      &-title {
        font-weight: 900;
      }

      &-speaker {
        font-size: 0.9em;
        font-style: italic;
        opacity: .7;
      }

      &-modules {
        margin-top: 10px;
      }

      &::marker {
        font-weight: 900;
      }
    }
  }
}
.demo-main-photo {
  height: calc(100vh - 122px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    height: calc(100vh - 100px);

    @media (max-width: $breakpoint-tablet) {
      height: calc(50vh - 80px);
    }
  }

  &-title {
    font-size: 60px;
    line-height: 72px;
    max-width: 90%;
    width: 1000px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 900;
    color: white;
    padding: 20px 70px;

    &-date {
      font-size: 30px;
      line-height: 36px;
      margin-top: 10px;


      @media (max-width: $breakpoint-desktop) {
        font-size: 26px;
        line-height: 32px;
        margin-top: 10px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
          line-height: 30px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 22px;
            line-height: 28px;
            margin-top: 5px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
      }
    }


    @media (max-width: $breakpoint-desktop) {
      font-size: 50px;
      line-height: 60px;
      max-width: 900px;

      @media (max-width: $breakpoint-tablet) {
        font-size: 36px;
        line-height: 44px;
        padding: 20px 40px;
        max-width: 900px;

        @media (max-width: $breakpoint-tablet-v) {
          font-size: 28px;
          line-height: 34px;
          width: 90%;
          padding: 20px;

          @media (max-width: $breakpoint-mobile) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }
}

.webinar-target {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: $breakpoint-desktop) {
    flex-direction: column !important;
  }

  &-title {
    width: 50%;
    box-sizing: border-box;
    padding: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 900;
    font-size: 60px;
    text-align: left;
    display: flex;
    justify-content: flex-start;

    &-inner {
      background-color: #5d5ca8;
      color: white;
      padding: 10px 30px;
    }
  }

  &-description {
    width: 50%;
    font-size: 25px;
    box-sizing: border-box;
    padding: 40px;
    text-align: right;
    line-height: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
  }
}

.webinar-target__active {
  flex-direction: row;

  .webinar-target-title {
    text-align: right;
    justify-content: flex-end;
  }

  .webinar-target-description {
    text-align: left;
  }
}

.marketing-section-analytics-wrapper__reverse {
  background-color: transparent !important;
  .marketing-section-analytics {
    flex-direction: row-reverse;
  }
}

#home-section-integrations-wrapper {
  #home-section-integrations {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 1100px;

    &-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      .home-section-integrations-logos-item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: calc(100% / 5);
        border: 1px solid white;

        img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: $breakpoint-tablet) {
          width: calc(100% / 5);

          @media (max-width: $breakpoint-tablet-v) {
            width: calc(100% / 5);
            padding: 10px;

            @media (max-width: $breakpoint-mobile) {
              width: calc(100% / 3);
              padding: 10px;
            }
          }
        }
      }
    }

    &-title {
    }
  }
}

.marketing-section-analytics-wrapper {
  background-color: #F9FAFF;

  .marketing-section-analytics {
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet-v) {
      flex-direction: column;
    }

    &-image {
      width: 50%;
      box-sizing: border-box;
      padding-right: 32px;
      overflow: hidden;

      img {
        width: 100%;
        border-radius: 20px;
      }

      @media (max-width: $breakpoint-desktop) {
        padding-right: 24px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            width: 70%;
            padding: 0;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              max-width: 400px;
            }
          }
        }
      }
    }

    &-info {
      flex: 1;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      flex-shrink: 0;
      font-weight: 900;
      font-size: 40px;
      padding-left: 32px;
      line-height: 52px;
      transition: .3s;

      @media (max-width: $breakpoint-desktop) {
        font-size: 34px;
        line-height: 40px;
        padding-left: 24px;

        @media (max-width: $breakpoint-tablet) {
          font-size: 30px;
          line-height: 36px;
          padding-left: 18px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 30px;
            line-height: 36px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;
          margin-bottom: 12px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }

      &-description {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        width: 80%;
        white-space: pre-wrap;

        @media (max-width: $breakpoint-tablet-v) {
          width: 80%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            max-width: 350px;
          }
        }
      }
    }
  }
}
</style>
